'use client'
import { useCallback, useState } from 'react'
import { parseIntSafe } from 'shared-utils'

export interface UseGenericRequestFetchResult<T> {
  unwrap: () => Promise<T>
}

const useGenericRequest = <T>() => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchNextRoute = useCallback((url: string, props?: RequestInit) => {
    setData(null)
    setError(null)
    setIsLoading(true)

    type ResultForPromise =
      | { status: 'PENDING' }
      | { status: 'SUCCESS'; data: T }
      | { status: 'ERROR'; error?: any }

    let result: ResultForPromise = {
      status: 'PENDING'
    }

    const resPromise = fetch(url, props)
      .then(async (res) => {
        let responseData

        // If there is content-length header we will take it into account, otherwise set 1
        const contentLength = parseIntSafe(res.headers.get('content-length')) ?? 1

        if (
          res.headers.get('content-type')?.includes('application/json') &&
          res.status !== 204 &&
          contentLength
        ) {
          responseData = await res.json()
        }

        if (res.ok) {
          setData(responseData)
          setError(null)
          result = {
            status: 'SUCCESS',
            data: responseData
          }

          return
        }

        const responseError = new Error(responseData?.error ?? 'Unknown error')
        setError(responseError)
        setData(null)

        result = {
          status: 'ERROR',
          error: responseError
        }
      })
      .catch((err) => {
        setError(err)
        setData(null)

        result = {
          status: 'ERROR',
          error: err
        }
      })
      .finally(() => {
        setIsLoading(false)
      })

    return {
      unwrap: () =>
        new Promise<T>((resolve, reject) => {
          resPromise.then(() => {
            if (result.status === 'SUCCESS') {
              resolve(result.data)
            } else if (result.status === 'ERROR') {
              // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors -- need to return error
              reject(result.error)
            }
          })
        })
    }
  }, [])

  const reset = useCallback(() => {
    setData(null)
    setError(null)
    setIsLoading(false)
  }, [])

  return { fetchNextRoute, data, error, isLoading, reset }
}

export default useGenericRequest
