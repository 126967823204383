import {
  type CreateOrderItemDTO,
  type DiscountDTO,
  type ICartItemDetailSubItem,
  type Option,
  type OrderItemDTO,
  type StorefrontOrderLine
} from 'ecosystem'
import { convertMinorUnitToValue } from 'shared-utils'
import { orderLineValueCalculator } from './payment'

export const parseStoreToBackendOrderLines = (
  items: StorefrontOrderLine[],
  cartDiscountCode: Option<DiscountDTO>
): CreateOrderItemDTO[] =>
  items.map((orderLine) => {
    const { product, services, subItems, ...restOfProduct } = orderLine
    const { totalDiscountAmount, unitPrice } = orderLineValueCalculator(orderLine, cartDiscountCode)

    return {
      ...restOfProduct,
      // the original unit price refers to a price before a discount code is applied
      originalUnitPrice: convertMinorUnitToValue(unitPrice),
      unitPrice: convertMinorUnitToValue(unitPrice - totalDiscountAmount / restOfProduct.quantity),
      productId: product.id,
      name: product.name,
      brand: product.brand?.name || '',
      slug: product.slug,
      services: services.map((s) => ({
        quantity: s.quantity,
        totalAmount: s.totalAmount,
        serviceId: s.service.id
      })),
      additionalInfo: '',
      subItems
    }
  })

export const parseCartSubItemsToBackendOrderSubItems = (
  cartSingleSubItems: ICartItemDetailSubItem[] | undefined,
  cartDiscountCode: Option<DiscountDTO>,
  vatRate: number
): CreateOrderItemDTO[] => {
  return (
    cartSingleSubItems?.map((cartSingleSubItem) => {
      const { qty: quantity = 1, product } = cartSingleSubItem

      const orderLine: StorefrontOrderLine = {
        product,
        quantity,
        originalPrice: product.originalPrice,
        unitPrice: product.price,
        vatRate,
        services: [],
        subItems: []
      }
      const { totalDiscountAmount, unitPrice } = orderLineValueCalculator(
        orderLine,
        cartDiscountCode
      )

      return {
        product,
        quantity,
        subItems: orderLine.subItems,
        vatRate: orderLine.vatRate,
        additionalInfo: '',
        originalUnitPrice: convertMinorUnitToValue(unitPrice),
        productId: product.id,
        services: [],
        unitPrice:
          convertMinorUnitToValue(unitPrice) -
          convertMinorUnitToValue(totalDiscountAmount / quantity)
      }
    }) || []
  )
}

export const transformSlugParamsIntoSlug = (slugArr: string[]) => {
  if (slugArr.length === 1) {
    return slugArr[0]
  }
  return slugArr.join('/')
}

export const transformOrderItemsToCreateOrderItems = (
  orderItems: OrderItemDTO[]
): CreateOrderItemDTO[] => {
  return orderItems.map(({ product, ...i }) => ({
    ...i,
    productId: product.id,
    services:
      i.services?.map((service) => ({
        quantity: service.quantity,
        serviceId: service.service.id,
        totalAmount: service.totalAmount
      })) || [],
    subItems: transformOrderItemsToCreateOrderItems(i.subItems || [])
  }))
}
