'use client'

import { useCallback, useState } from 'react'

export interface UseReqFetchResult<T> {
  unwrap: () => Promise<T>
}

/**
 * Custom React hook for handling asynchronous requests with state management.
 * Could be useful for calling server actions.
 *
 * This hook provides:
 * - `fetch`: A function to initiate the request. Returns an object with method `unwrap` that returns a
 * Promise for explicit handling.
 * - `data`: The response data.
 * - `error`: Any error message encountered.
 * - `isLoading`: A boolean indicating loading state.
 * - `reset`: A function to reset the state.
 *
 * Example usage:
 * ```ts
 * const { fetch, isLoading, data, error } = useReq<MyDataType>();
 *
 * const handleRequest = () => {
 *   fetch(() => myApiRequest())
 *   .unwrap()
 *   .then(result => { console.log('Received Data:', responseData) });
 * }
 * ```
 * @remarks Server actions aren't allowed until we use pages router in any project
 */
export const useReq = <T>() => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<string | null>()
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback((fetcher: () => Promise<T>): UseReqFetchResult<T> => {
    setIsLoading(true)
    setError(undefined)

    let result: { status: 'PENDING' | 'SUCCESS' | 'ERROR'; data?: T; error?: any } = {
      status: 'PENDING'
    }

    const resPromise = fetcher()
      .then((response) => {
        setData(response)
        setError(null)
        result = { status: 'SUCCESS', data: response }
      })
      .catch((err) => {
        setError(err)
        setData(null)
        result = { status: 'ERROR', error: err }
      })
      .finally(() => {
        setIsLoading(false)
      })

    return {
      unwrap: () =>
        new Promise<T>((resolve, reject) => {
          resPromise.then(() => {
            if (result.status === 'SUCCESS') {
              resolve(result.data as T)
            } else if (result.status === 'ERROR') {
              // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors -- expects Error
              reject(result.error)
            }
          })
        })
    }
  }, [])

  const reset = useCallback(() => {
    setError(null)
    setData(null)
    setIsLoading(false)
  }, [])

  return {
    fetch,
    data,
    reset,
    error,
    isLoading
  }
}
