import {
  type AiOfferAcceptRequestDTO,
  type AiOfferAcceptResponseDTO,
  type AiOfferAnalyticsProductPageViewEventDTO,
  type AiOfferAnalyticsRequestDTO,
  type AiOfferAnalyticsResponseDTO,
  type AiOfferListRequestDTO,
  type AiOfferListResponseDTO,
  type AiOfferReadRequestDTO,
  type AiOfferReadResponseDTO
} from 'ecosystem'
import { createUrl } from 'shared-utils'
import {
  apiCall,
  createError,
  fetchPropsFactory,
  type StoreFrontApiCall,
  storefrontApiCall
} from '../utils/fetching'
import { BASE_STOREFRONT_ENDPOINT } from './storeEndpoint'

const customerDealsEndpoint = `${BASE_STOREFRONT_ENDPOINT}/customer-deals`

const getAiOfferList = async (params: AiOfferListRequestDTO) => {
  const url = createUrl(customerDealsEndpoint, params)

  const { data: deals, error } = await storefrontApiCall<AiOfferListResponseDTO>(
    url,
    fetchPropsFactory()
  )

  if (error) {
    throw createError(error)
  }

  return deals
}

const readAiOffer = async ({ offerId }: AiOfferReadRequestDTO) => {
  const { data, error, response } = await apiCall<StoreFrontApiCall<null>>(
    `${customerDealsEndpoint}/${offerId}/opened`,
    fetchPropsFactory({ props: { method: 'PUT' } })
  )

  if (error) {
    throw createError(error)
  }
  if (!response?.ok) {
    throw createError(data ?? `Api error: ${response?.status} ${response?.statusText}`)
  }

  return { success: true } satisfies AiOfferReadResponseDTO
}

const acceptAiOffer = async ({ offerId }: AiOfferAcceptRequestDTO) => {
  const { data, error, response } = await apiCall<StoreFrontApiCall<null>>(
    `${customerDealsEndpoint}/${offerId}/accepted`,
    fetchPropsFactory({ props: { method: 'PUT' } })
  )

  if (error) {
    throw createError(error)
  }

  if (!response?.ok) {
    throw createError(data ?? `Api error: ${response?.status} ${response?.statusText}`)
  }

  return { success: true } satisfies AiOfferAcceptResponseDTO
}

const sendAnalyticsAiOffer = async (params: AiOfferAnalyticsRequestDTO) => {
  const payload = JSON.stringify({
    sessionId: params.userSessionId ?? '',
    token: params.token,
    slug: params?.data?.product?.slug ?? ''
  } satisfies AiOfferAnalyticsProductPageViewEventDTO)

  const { data, error, response } = await apiCall<StoreFrontApiCall<null>>(
    `${customerDealsEndpoint}/product`,
    fetchPropsFactory({ props: { method: 'POST', body: payload } })
  )

  if (error) {
    throw createError(error)
  }

  if (!response?.ok) {
    throw createError(data ?? `Api error: ${response?.status} ${response?.statusText}`)
  }

  return { success: true } satisfies AiOfferAnalyticsResponseDTO
}

export const aiOfferApi = {
  list: getAiOfferList,
  readOffer: readAiOffer,
  acceptOffer: acceptAiOffer,
  sendAnalytics: sendAnalyticsAiOffer
}
