import {
  type BundleDTO,
  type BundleFullDTO,
  type OldProductDTO,
  type Option,
  type ProductImages,
  type ProductMedia,
  ProductMediaType
} from 'ecosystem'
import storefrontApi from '../storefront-api'
import { httpImageFactory } from './fetching'

/**  VARIANT GROUP PRODUCTS DETAILS FETCH */
export async function variantsFactory(
  variantGroupId: string | null
): Promise<OldProductDTO[] | null> {
  if (!variantGroupId) {
    return null
  }
  return storefrontApi.products.variantsByGroupId(variantGroupId)
}

/**  VARIANT GROUPS PRODUCTS DETAILS FETCH */
export async function variantsByIdsFactory(
  variantGroupIds?: (string | null)[]
): Promise<Option<Record<string, { data?: OldProductDTO[] | null; error?: string }>>> {
  const filteredIds = variantGroupIds?.filter((id) => typeof id === 'string')

  if (!filteredIds?.length) {
    return null
  }

  const variantsByIds: Record<string, { data?: OldProductDTO[] | null; error?: string }> = {}

  await Promise.all(
    filteredIds.map((variantGroupId) =>
      storefrontApi.products
        .variantsByGroupId(variantGroupId)
        .then((res) => {
          variantsByIds[variantGroupId] = { data: res }
        })
        .catch((err: unknown) => {
          // eslint-disable-next-line no-console -- show it in console when it builds
          console.error(err)
          if (err instanceof Error) {
            variantsByIds[variantGroupId] = { error: err.message }
          } else {
            variantsByIds[variantGroupId] = { error: 'Unknown error' }
          }
        })
    )
  )

  return variantsByIds
}

/**  BUNDLE PRODUCTS DETAILS FETCH */
export async function bundleProductsFactory(
  bundles: BundleDTO[] | null
): Promise<BundleFullDTO[] | null> {
  if (!bundles?.length) {
    return null
  }

  const bundleProductsResponse: BundleFullDTO[] = []

  for (const { productId, quantity } of bundles) {
    const product = await storefrontApi.products.productById(productId)

    if (product) {
      bundleProductsResponse.push({ product, quantity })
    }
  }

  return bundleProductsResponse
}

export const getProductImages = (medias: ProductMedia[] | null): ProductImages => {
  const mainImages = medias?.filter((mediaItem) => mediaItem.main)
  const secondaryImages = medias?.filter(
    (mediaItem) => !mediaItem.main && mediaItem.type === ProductMediaType.IMAGE
  )
  const mainImageUrl = httpImageFactory(
    medias && mainImages?.length ? mainImages[0].url : secondaryImages?.shift()?.url || ''
  )
  const secondaryImageUrl = httpImageFactory(secondaryImages?.shift()?.url || '')

  return {
    mainImages,
    secondaryImages,
    mainImageUrl,
    secondaryImageUrl
  }
}
