import { findNode } from 'shared-utils'
import {
  type CategoryDTO,
  type DeliveryDTO,
  type Option,
  type RelatedWidget,
  type StoreDTO,
  type StorefrontWidget,
  StorefrontWidgetTypeAll,
  type TagDTO,
  type TagLightDTO,
  type WidgetStaticPageCategory
} from 'ecosystem'
import { widgetApi } from '../storefront-api/widgetEndpoint'

export const categoryFactory = (slug: string, categories: Option<CategoryDTO[]>) => {
  let category: unknown
  let parentCategories: unknown

  categories?.forEach((item) => {
    const result = findNode(slug, 'subCategories', item)

    if (result !== false) {
      category = result.currentNode
      parentCategories = result.parentNodes
    }
  })

  return {
    category: category as CategoryDTO | undefined,
    parentCategories: parentCategories as CategoryDTO[]
  }
}

export const brandFactory = (slug: string, brands: StoreDTO['brands']) => {
  return brands?.find((brand) => brand.slug === slug)
}

export const tagFactory = (slug: string, tags: Option<TagDTO[]>) => {
  return tags?.find((tag) => tag.slug === slug)
}

export const badgesFactory = (badges: TagLightDTO[], productTagIds: string[]) => {
  return badges.filter((badge) => productTagIds.includes(badge.id))
}

export const deliveryFactory = (
  deliveryOptions: DeliveryDTO[] | null,
  deliveryId: string
): DeliveryDTO | null => deliveryOptions?.find((delivery) => delivery.id === deliveryId) ?? null

export async function staticPagesCategoryFactory(): Promise<RelatedWidget[]> {
  const widgets = (await widgetApi.getWidgets({
    view: 'STATIC_VIEWS',
    type: StorefrontWidgetTypeAll.WIDGET_STATIC_VIEW_CATEGORY,
    filter: {
      key: 'name',
      textValues: ['Static Pages']
    }
  })) as StorefrontWidget<WidgetStaticPageCategory>[]

  return widgets.length ? widgets[0].widget.relatedStaticPages : []
}

const getCategorySubcategories = (o: any) => {
  const all: string[] = []

  const recursion = (x: any) => {
    all.push(x.slug)
    o.subCategories?.forEach(recursion)
  }

  recursion(o)

  return all
}

export const getAllCategoriesSlugs = (categories: CategoryDTO[]) => {
  const allCategories: string[][] = []

  categories.forEach((category: CategoryDTO) => {
    allCategories.push(getCategorySubcategories(category))
  })

  return allCategories.flat()
}

export function isOdd(num: number) {
  return num % 2
}
